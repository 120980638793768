export const languageEn = {
	translations: {
		home: 'HOME',
		about: 'ABOUT',
		'about us': 'ABOUT US',
		'about Us': 'About Us',
		business: 'BUSINESS',
		busiNess: 'BUSINESS',
		responsibility: 'RESPONSIBILITY',
		'our responsibility': 'Our Responsibility',
		contact: 'CONTACT',
		'MAKE INNOVATION A ROUTINE, ': 'MAKE INNOVATION A ROUTINE, ',
		'TAKE THE ROUTINE TO THE EXTREME.': 'TAKE THE ROUTINE TO THE EXTREME.',
		'our business': 'Our Business',
		halifax: 'HALIFAX',
		vancouver: 'VANCOUVER',
		qingdao: 'QINGDAO',

		//home page
		"RIGHT SOURCE GROUP LIMITED, located in Halifax, Nova Scotia, was founded in 2011. RSGL have high reputation in the market. At present, the company's independent brand SEACOO has become reputational and leads the market with a sales growth rate of 30% per year.":
			"RIGHT SOURCE GROUP LIMITED, located in Halifax, Nova Scotia, was founded in 2011. RSGL have high reputation in the market. At present, the company's independent brand SEACOO has become reputational and leads the market with a sales growth rate of 30% per year.",
		'We take responsibility for consumers, communities and the environment. We do not take profit as the only goal. We emphasize the concern for human value in the production process, and emphasize the contribution to the environment, consumers and society.':
			'We take responsibility for consumers, communities and the environment. We do not take profit as the only goal. We emphasize the concern for human value in the production process, and emphasize the contribution to the environment, consumers and society.',

		//business page
		'SEACOO was built in 2011, over 10 years of developing in Canadian sea cucumber industry, it becomes the biggest sea cucumber supplier in North America, two processing plants in each of Halifax and Vancouver been processing total of 6 million lbs of Canadian sea cucumber each year, with a professional quality control team under CFIA approval, SEACOO will always supply the best quality products for customer.':
			'SEACOO was built in 2011, over 10 years of developing in Canadian sea cucumber industry, it becomes the biggest sea cucumber supplier in North America, two processing plants in each of Halifax and Vancouver been processing total of 6 million lbs of Canadian sea cucumber each year, with a professional quality control team under CFIA approval, SEACOO will always supply the best quality products for customer.',
		'future Resource Itd is a company founded in nova scotia and mainly focus on Canadian diving fish seafood products, start from 2018, company been invested 10 million dollar on geoduck quota, sea cucumber quota and sea urchin quota, after two years development, company has achieved very steady progress on fishing and processing and marketing, in 2020, company opened process and more retail packing products development.':
			'Future Resource Itd is a company founded in nova scotia and mainly focus on Canadian diving fish seafood products, start from 2018, company been invested 10 million dollar on geoduck quota, sea cucumber quota and sea urchin quota, after two years development, company has achieved very steady progress on fishing and processing and marketing, in 2020, company opened process and more retail packing products development.',
		'freshook Inc. was founded in 2017 and established a processing plant in Qingdao, China in the same year. The company is committed to the development of breaded aquatic products, including breaded cod, breaded squid, breaded scallops, breaded shrimp, etc.':
			'Freshook Inc. was founded in 2017 and established a processing plant in Qingdao, China in the same year. The company is committed to the development of breaded aquatic products, including breaded cod, breaded squid, breaded scallops, breaded shrimp, etc.',

		//responsibility page
		'community responsibility': 'Community Responsibility',

		Sustainability: 'Sustainability',
		'Fisheries and aquaculture play a key role in the nutrition of hundreds of millions of people around the world every day. But with climate change, fishing pressure and pollution from various human activities are causing ocean acidification and declining biodiversity. Instead of fishing unrestrainedly or causing a heavy load to the ocean, we insist on developing our fishing industry in a sustainable way. ':
			'Fisheries and aquaculture play a key role in the nutrition of hundreds of millions of people around the world every day. But with climate change, fishing pressure and pollution from various human activities are causing ocean acidification and declining biodiversity. Instead of fishing unrestrainedly or causing a heavy load to the ocean, we insist on developing our fishing industry in a sustainable way.',
		'We will also carefully screen and return the undeveloped marine life to the ocean during the process of fishing, to ensure the sustainable development of the marine environment. We believe that only the healthy oceans and sustainable ecosystem for marine life can provide critical support functions upon which human health and well-being depend.':
			'We will also carefully screen and return the undeveloped marine life to the ocean during the process of fishing, to ensure the sustainable development of the marine environment. We believe that only the healthy oceans and sustainable ecosystem for marine life can provide critical support functions upon which human health and well-being depend.',

		'Our Commitment to People': 'Our Commitment to People',
		'Ensuring the occupational safety, health, and welfare of workers is one of the main achievements we have since we have built our business. We provide professional training and health insurance for every position, to give our employees a safe environment and guarantee.':
			'Ensuring the occupational safety, health, and welfare of workers is one of the main achievements we have since we have built our business. We provide professional training and health insurance for every position, to give our employees a safe environment and guarantee.',

		'At the same time, the self-development of employees is the culture of our company. We encourage our employees to learn more skills and knowledge in different disciplines. We truly believe that we are growing with the development of our employees, not only with their increasing skills and abilities, but also with their border views and innovation.':
			'At the same time, the self-development of employees is the culture of our company. We encourage our employees to learn more skills and knowledge in different disciplines. We truly believe that we are growing with the development of our employees, not only with their increasing skills and abilities, but also with their border views and innovation.',
		'Environmental-Friendly': 'Environmental-Friendly',
		'The oceans are under increasing stress from multiple urban and rural sources of pollution. Those are causing damage to the marine environment and lives, and for example, pharmaceutical residues, microplastics, or other substances can have harmful consequences for human health.':
			'The oceans are under increasing stress from multiple urban and rural sources of pollution. Those are causing damage to the marine environment and lives, and for example, pharmaceutical residues, microplastics, or other substances can have harmful consequences for human health.',
		'As an environmental-friendly and user-central company, protecting the ocean and preventing marine pollution is one of the main duties and responsibilities, not only we intend to ensure the high quality of our products, but also contribute our power to the sustainable development of the marine industry of Canada. Meanwhile, we employ environmentally friendly processes in our production, to ensure sustainable consumption and production patterns.':
			'As an environmental-friendly and user-central company, protecting the ocean and preventing marine pollution is one of the main duties and responsibilities, not only we intend to ensure the high quality of our products, but also contribute our power to the sustainable development of the marine industry of Canada. Meanwhile, we employ environmentally friendly processes in our production, to ensure sustainable consumption and production patterns.',

		//about page
		founded: 'Founded',
		'RIGHT SOURCE GROUP LIMITED, located in Halifax, Nova Scotia, was founded in 2011. The company’s products, such as dried sea cucumber and fish maw, have high reputation in the market.':
			'RIGHT SOURCE GROUP LIMITED, located in Halifax, Nova Scotia, was founded in 2011. The company’s products, such as dried sea cucumber and fish maw, have high reputation in the market.',

		Development: 'Development',
		'Since 2017, RIGHT SOURCE GROUP LIMITED has invested more than 5 million Canadian dollars to acquire sea cucumber resources on the east and west coasts of Canada, and actively develop marine health products. We hope to drive a larger labor market in the near future to meet greater market demand. Today, the company has dozens of bestsellers. These products are sold all over the world and their distribution points are all over North America and China.':
			'Since 2017, RIGHT SOURCE GROUP LIMITED has invested more than 5 million Canadian dollars to acquire sea cucumber resources on the east and west coasts of Canada, and actively develop marine health products. We hope to drive a larger labor market in the near future to meet greater market demand. Today, the company has dozens of bestsellers. These products are sold all over the world and their distribution points are all over North America and China.',

		'The Present': 'The Present',
		"At present, the company's independent brand SEACOO has become extremely famous and leads the market with a sales growth rate of 30% per year. The company has developed in-depth cooperation and communication with local fishermen and factories, and has established a solid supply chain, which played a key role in the process of integrating local stealth fishery vibrato into the world stage.":
			"At present, the company's independent brand SEACOO has become extremely famous and leads the market with a sales growth rate of 30% per year.The company has developed in-depth cooperation and communication with local fishermen and factories, and has established a solid supply chain, which played a key role in the process of integrating local stealth fishery vibrato into the world stage.",

		Resource: 'Resource',
		"Canadian dried sea cucumber is the company's main product, divided into two products, which are the East Coastsea cucumber and the West Coast Red Sea Cucumber. The company has approximately 6 million pounds of the East Coast sea cucumber quotas, and a total of four large fishing vessels to harvest throughout the year. The company directly or indirectly controls approximately 600,000 pounds of red sea cucumber quotas on the West Coast, with two fishing vessels and four divers. At the same time, the company has established partnerships with a number of local exporters and processing plants and has established supply relationships with retailers such as Lablow and Sobeys.":
			"Canadian dried sea cucumber is the company's main product, divided into two products, which are the East Coastsea cucumber and the West Coast Red Sea Cucumber. The company has approximately 6 million pounds of the East Coast sea cucumber quotas, and a total of four large fishing vessels to harvest throughout the year. The company directly or indirectly controls approximately 600,000 pounds of red sea cucumber quotas on the West Coast, with two fishing vessels and four divers. At the same time, the company has established partnerships with a number of local exporters and processing plants and has established supply relationships with retailers such as Lablow and Sobeys.",

		MILESTONE: 'MILESTONE',
		'The company was founded in Halifax, Canada':
			'The company was founded in Halifax, Canada',
		'Establish close cooperative relations with Canadian local exporters and fishery factories':
			'Establish close cooperative relations with Canadian local exporters and fishery factories',
		'Established supply relationships with local retailers such as Lablow and Sobeys':
			'Established supply relationships with local retailers such as Lablow and Sobeys',
		'Launched marine health food development projects, and cooperated with scientific research institutions and local universities to develop sea cucumber health products':
			'Launched marine health food development projects, and cooperated with scientific research institutions and local universities to develop sea cucumber health products',
		'Beche Sea Cucumber Capsule Health Products was launched and received attention and praise from various industries':
			'Beche Sea Cucumber Capsule Health Products was launched and received attention and praise from various industries',
		'Established a West Coast processing base at Albeney Port on Vancouver Island and coordinated development with the East Coast':
			'Established a West Coast processing base at Albeney Port on Vancouver Island and coordinated development with the East Coast',

		'Fully develop seafood import and export business':
			'Fully develop seafood import and export business',
		'The Canadian sea cucumber brand seacoo was officially launched, the development of sea cucumbers on the east and west coasts of Canada was started, and a good relationship was established with the local sea cucumber industry':
			'The Canadian sea cucumber brand seacoo was officially launched, the development of sea cucumbers on the east and west coasts of Canada was started, and a good relationship was established with the local sea cucumber industry',
		'Started the construction of the freshook factory in Qingdao, laying the foundation for the expansion of the Chinese market':
			'Started the construction of the freshook factory in Qingdao, laying the foundation for the expansion of the Chinese market',
		"The construction of Freshook's deep processing plant for aquatic products has been completed, and the breaded cod products and deep-processed sea cucumber products have begun to be sold to the Chinese market":
			"The construction of Freshook's deep processing plant for aquatic products has been completed, and the breaded cod products and deep-processed sea cucumber products have begun to be sold to the Chinese market",
		'Established the future resource company to purchase and integrate fishery resources, and successively purchased fishery resources on the west coast of Canada for more than 10 million Canadian dollars':
			'Established the future resource company to purchase and integrate fishery resources, and successively purchased fishery resources on the west coast of Canada for more than 10 million Canadian dollars',

		'Our Locations': 'Our Locations',
		VANCOUVER: 'VANCOUVER',
		SEATTLE: 'SEATTLE',
		HALIFAX: 'HALIFAX',
		'NEW YORK': 'NEW YORK',
		QINGDAO: 'QINGDAO',
		HONGKONG: 'HONGKONG',

		// contact page
		'Contact Form': 'Contact Form',
		'Contact<br />Information': 'Contact<br />Information',
		'Your message has been sent!': 'Your message has been sent!',
		Name: 'Name',
		'E-mail': 'E-mail',
		Message: 'Message',
		'Please input a valid e-mail address':
			'Please input a valid e-mail address',
		Phone: 'Phone',
		'Toll Free': 'Toll Free'
	}
}
