import React from 'react'

import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const BusinessCard = (props: any) => {
	const { i18n } = useTranslation()
	return (
		<div className={props.className}>
			<div className='logo'>
				<img src={props.imgUrl} alt={props.name} style={props.style} />
			</div>
			<div className={i18n.language === 'en' ? 'info-en' : 'info-zh'}>
				<Typography>{props.info}</Typography>
			</div>
		</div>
	)
}

export default BusinessCard
