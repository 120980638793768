import React from 'react'

import { useTranslation } from 'react-i18next'

import { Typography, Breadcrumbs } from '@material-ui/core'

import './style.scss'

const About = () => {
	const { t, i18n } = useTranslation()
	const bannerWidth = document.body.offsetWidth
	const bannerHeight = bannerWidth / 2.7
	return (
		<div id='about'>
			<div className='about-banner' style={{ minHeight: bannerHeight }}>
				<Typography className='title'>{t('about Us')}</Typography>
			</div>
			<div className='about-body1'>
				<div className='about-body1-left'>
					<img
						className='about-body1-img'
						src='/images/about/about1.jpg'
						alt='about1'
						style={{ marginBottom: 80 }}
					/>
					<img
						className='about-body1-img'
						src='/images/about/about2.jpg'
						alt='about2'
						style={{ marginBottom: 160 }}
					/>
					<img
						className='about-body1-img'
						src='/images/about/about3.jpg'
						alt='about3'
						style={{ marginBottom: 170 }}
					/>
					<img
						className='about-body1-img'
						src='/images/about/about4.jpg'
						alt='about4'
					/>
				</div>
				<div className='about-body1-right'>
					<div className='about-body1-text' style={{ height: 200 }}>
						<Typography className='title' variant='h2'>
							{t('founded')}
						</Typography>
						<Typography
							className={i18n.language === 'en' ? 'content-en' : 'content-zh'}
						>
							{t(
								'RIGHT SOURCE GROUP LIMITED, located in Halifax, Nova Scotia, was founded in 2011. The company’s products, such as dried sea cucumber and fish maw, have high reputation in the market.'
							)}
						</Typography>
					</div>
					<div className='about-body1-text' style={{ height: 320 }}>
						<Typography className='title' variant='h2'>
							{t('Development')}
						</Typography>
						<Typography
							className={i18n.language === 'en' ? 'content-en' : 'content-zh'}
						>
							{t(
								'Since 2017, RIGHT SOURCE GROUP LIMITED has invested more than 5 million Canadian dollars to acquire sea cucumber resources on the east and west coasts of Canada, and actively develop marine health products. We hope to drive a larger labor market in the near future to meet greater market demand. Today, the company has dozens of bestsellers. These products are sold all over the world and their distribution points are all over North America and China.'
							)}
						</Typography>
					</div>
					<div className='about-body1-text' style={{ height: 300 }}>
						<Typography className='title' variant='h2'>
							{t('The Present')}
						</Typography>
						<Typography
							className={i18n.language === 'en' ? 'content-en' : 'content-zh'}
						>
							{t(
								"At present, the company's independent brand SEACOO has become extremely famous and leads the market with a sales growth rate of 30% per year. The company has developed in-depth cooperation and communication with local fishermen and factories, and has established a solid supply chain, which played a key role in the process of integrating local stealth fishery vibrato into the world stage."
							)}
						</Typography>
					</div>
					<div className='about-body1-text' style={{ height: 200 }}>
						<Typography className='title' variant='h2'>
							{t('Resource')}
						</Typography>
						<Typography
							className={i18n.language === 'en' ? 'content-en' : 'content-zh'}
						>
							{t(
								"Canadian dried sea cucumber is the company's main product, divided into two products, which are the East Coastsea cucumber and the West Coast Red Sea Cucumber. The company has approximately 6 million pounds of the East Coast sea cucumber quotas, and a total of four large fishing vessels to harvest throughout the year. The company directly or indirectly controls approximately 600,000 pounds of red sea cucumber quotas on the West Coast, with two fishing vessels and four divers. At the same time, the company has established partnerships with a number of local exporters and processing plants and has established supply relationships with retailers such as Lablow and Sobeys."
							)}
						</Typography>
					</div>
				</div>
			</div>
			<div className='about-body2'>
				<div className='about-body2-title'>
					<Typography className='title' variant='h1'>
						{t('MILESTONE')}
					</Typography>
				</div>
				<div className='about-body2-left'>
					<div className='left'>
						<div className='info'>
							<Typography className='year'>2011/12</Typography>
							<Typography className='event'>
								{t('The company was founded in Halifax, Canada')}
							</Typography>
						</div>
						<div className='info'>
							<Typography className='year'>2013/06</Typography>
							<Typography className='event'>
								{t(
									'Establish close cooperative relations with Canadian local exporters and fishery factories'
								)}
							</Typography>
						</div>
						<div className='info'>
							<Typography className='year'>2014/10</Typography>
							<Typography className='event'>
								{t(
									'Established supply relationships with local retailers such as Lablow and Sobeys'
								)}
							</Typography>
						</div>
						<div className='info'>
							<Typography className='year'>2016/01</Typography>
							<Typography className='event'>
								{t(
									'Launched marine health food development projects, and cooperated with scientific research institutions and local universities to develop sea cucumber health products'
								)}
							</Typography>
						</div>
						<div className='info'>
							<Typography className='year'>2017/12</Typography>
							<Typography className='event'>
								{t(
									'Beche Sea Cucumber Capsule Health Products was launched and received attention and praise from various industries'
								)}
							</Typography>
						</div>
						<div className='info'>
							<Typography className='year'>2019</Typography>
							<Typography className='event'>
								{t(
									'Established a West Coast processing base at Albeney Port on Vancouver Island and coordinated development with the East Coast'
								)}
							</Typography>
						</div>
					</div>
				</div>
				<div className='about-body2-right'>
					<div className='right'>
						<div className='info'>
							<Typography className='year'>2012</Typography>
							<Typography className='event'>
								{t('Fully develop seafood import and export business')}
							</Typography>
						</div>
						<div className='info'>
							<Typography className='year'>2013/12</Typography>
							<Typography className='event'>
								{t(
									'The Canadian sea cucumber brand seacoo was officially launched, the development of sea cucumbers on the east and west coasts of Canada was started, and a good relationship was established with the local sea cucumber industry'
								)}
							</Typography>
						</div>
						<div className='info'>
							<Typography className='year'>2015/06</Typography>
							<Typography className='event'>
								{t(
									'Started the construction of the freshook factory in Qingdao, laying the foundation for the expansion of the Chinese market'
								)}
							</Typography>
						</div>
						<div className='info'>
							<Typography className='year'>2017/06</Typography>
							<Typography className='event'>
								{t(
									"The construction of Freshook's deep processing plant for aquatic products has been completed, and the breaded cod products and deep-processed sea cucumber products have begun to be sold to the Chinese market"
								)}
							</Typography>
						</div>
						<div className='info'>
							<Typography className='year'>2018</Typography>
							<Typography className='event'>
								{t(
									'Established the future resource company to purchase and integrate fishery resources, and successively purchased fishery resources on the west coast of Canada for more than 10 million Canadian dollars'
								)}
							</Typography>
						</div>
					</div>
				</div>
			</div>
			<div className='about-body3'>
				<Typography className='title'>{t('Our Locations')}</Typography>
				<img src='/images/about/about-map.png' alt='map' />
				<Breadcrumbs className='about-body3-footer'>
					<Typography>{t('VANCOUVER')}</Typography>
					<Typography>{t('SEATTLE')}</Typography>
					<Typography>{t('HALIFAX')}</Typography>
					<Typography>{t('NEW YORK')}</Typography>
					<Typography>{t('QINGDAO')}</Typography>
					<Typography>{t('HONGKONG')}</Typography>
				</Breadcrumbs>
			</div>
		</div>
	)
}

export default About
