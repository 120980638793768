import React from 'react'

import { useTranslation } from 'react-i18next'

import { Typography } from '@material-ui/core'

import BusinessCard from '../../component/BusinessCard/BusinessCard'
import { businessCompanies } from '../../constants/index'

import './style.scss'

const Business = () => {
	const { t } = useTranslation()
	const bannerWidth = document.body.offsetWidth
	const bannerHeight = bannerWidth / 2.57
	return (
		<div id='business'>
			<div className='business-banner' style={{ minHeight: bannerHeight }}>
				<img src='/images/business/businessbanner.jpg' alt='businessBanner' />
				<Typography className='title' variant='inherit'>
					{t('our business')}
				</Typography>
			</div>
			<div className='business-body'>
				<a
					href='https://www.seacoo.com/'
					target='_blank'
					rel='noopener noreferrer'
				>
					<BusinessCard
						className={businessCompanies.seacoo.class}
						imgUrl={businessCompanies.seacoo.imgUrl}
						name={businessCompanies.seacoo.name}
						info={t(businessCompanies.seacoo.info)}
						style={businessCompanies.seacoo.style}
					/>
				</a>
				<a href='/' target='_blank' rel='noopener noreferrer'>
					<BusinessCard
						className={businessCompanies.fr.class}
						imgUrl={businessCompanies.fr.imgUrl}
						name={businessCompanies.fr.name}
						info={t(businessCompanies.fr.info)}
						style={businessCompanies.fr.style}
					/>
				</a>
				<a rel='noopener noreferrer'>
					<BusinessCard
						className={businessCompanies.freshook.class}
						imgUrl={businessCompanies.freshook.imgUrl}
						name={businessCompanies.freshook.name}
						info={t(businessCompanies.freshook.info)}
						style={businessCompanies.freshook.style}
					/>
				</a>
			</div>
		</div>
	)
}

export default Business
