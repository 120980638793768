import React from 'react'

import { useTranslation } from 'react-i18next'

import { Typography } from '@material-ui/core'

import './style.scss'

const Responsibility = () => {
	const { t, i18n } = useTranslation()
	const bannerWidth = document.body.offsetWidth
	const bannerHeight = bannerWidth / 2.57
	return (
		<div id='responsibility'>
			<div
				className='responsibility-banner'
				style={{ minHeight: bannerHeight }}
			>
				<img
					src='/images/responsibility/resbannershadow.png'
					alt='responsibilityBanner'
				/>
				<Typography className='title'>{t('our responsibility')}</Typography>
			</div>
			<div
				className={
					i18n.language === 'en'
						? 'responsibility-body1'
						: 'responsibility-body1-zh'
				}
			>
				<div className='content'>
					<Typography
						variant='h2'
						className={i18n.language === 'en' ? 'title-en' : 'title-zh'}
					>
						{t('Sustainability')}
					</Typography>
					<Typography
						className={i18n.language === 'en' ? 'info-en' : 'info-zh'}
					>
						{t(
							'Fisheries and aquaculture play a key role in the nutrition of hundreds of millions of people around the world every day. But with climate change, fishing pressure and pollution from various human activities are causing ocean acidification and declining biodiversity. Instead of fishing unrestrainedly or causing a heavy load to the ocean, we insist on developing our fishing industry in a sustainable way. '
						)}
						<br />
						<br />
						{t(
							'We will also carefully screen and return the undeveloped marine life to the ocean during the process of fishing, to ensure the sustainable development of the marine environment. We believe that only the healthy oceans and sustainable ecosystem for marine life can provide critical support functions upon which human health and well-being depend.'
						)}
					</Typography>
					<div className='images'>
						<img
							className='img1'
							src='/images/responsibility/res-1.png'
							alt='placeholder'
						/>
						<img
							className='img2'
							src='/images/responsibility/res-2.png'
							alt='placeholder'
						/>
					</div>
				</div>
			</div>
			<div className='responsibility-body2'>
				<div className='content'>
					<Typography
						variant='h2'
						className={i18n.language === 'en' ? 'title-en' : 'title-zh'}
					>
						{t('Our Commitment to People')}
					</Typography>
					<Typography
						className={i18n.language === 'en' ? 'info-en' : 'info-zh'}
					>
						{t(
							'Ensuring the occupational safety, health, and welfare of workers is one of the main achievements we have since we have built our business. We provide professional training and health insurance for every position, to give our employees a safe environment and guarantee.'
						)}
						<br />
						<br />
						{t(
							'At the same time, the self-development of employees is the culture of our company. We encourage our employees to learn more skills and knowledge in different disciplines. We truly believe that we are growing with the development of our employees, not only with their increasing skills and abilities, but also with their border views and innovation.'
						)}
					</Typography>
					<div className='images'>
						<img
							className='img1'
							src='/images/responsibility/res-3.png'
							alt='placeholder'
						/>
						<img
							className='img2'
							src='/images/responsibility/res-4.png'
							alt='placeholder'
						/>
					</div>
				</div>
			</div>
			<div className='responsibility-body3'>
				<div className='content'>
					<Typography
						variant='h2'
						className={i18n.language === 'en' ? 'title-en' : 'title-zh'}
					>
						{t('Environmental-Friendly')}
					</Typography>
					<Typography
						className={i18n.language === 'en' ? 'info-en' : 'info-zh'}
					>
						{t(
							'The oceans are under increasing stress from multiple urban and rural sources of pollution. Those are causing damage to the marine environment and lives, and for example, pharmaceutical residues, microplastics, or other substances can have harmful consequences for human health.'
						)}
						<br />
						<br />
						{t(
							'As an environmental-friendly and user-central company, protecting the ocean and preventing marine pollution is one of the main duties and responsibilities, not only we intend to ensure the high quality of our products, but also contribute our power to the sustainable development of the marine industry of Canada. Meanwhile, we employ environmentally friendly processes in our production, to ensure sustainable consumption and production patterns.'
						)}
					</Typography>
					<div className='images'>
						<img
							className='img1'
							src='/images/responsibility/res-5.png'
							alt='placeholder'
						/>
						<img
							className='img2'
							src='/images/responsibility/res-6.png'
							alt='placeholder'
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Responsibility
