import React, { Component } from 'react'

class Logo extends Component {
	render() {
		return (
			<div className='logo'>
				<a href='/'>
					<img src={'/images/logo/logo.png'} alt='logo' />
				</a>
			</div>
		)
	}
}

export default Logo
