import { languageEn } from './languageEn'
import { languageZh } from './languageZh'

export const pathObj = {
	home: {
		pathName: ''
	},
	about: {
		pathName: 'about'
	},
	business: {
		pathName: 'business'
	},
	responsibility: {
		pathName: 'responsibility'
	},
	contact: {
		pathName: 'contact'
	}
}

export const businessCompanies = {
	seacoo: {
		name: 'seacoo',
		class: 'card1',
		imgUrl: '/images/business/seacoo.png',
		style: {
			width: '60%'
		},
		info:
			'SEACOO was built in 2011, over 10 years of developing in Canadian sea cucumber industry, it becomes the biggest sea cucumber supplier in North America, two processing plants in each of Halifax and Vancouver been processing total of 6 million lbs of Canadian sea cucumber each year, with a professional quality control team under CFIA approval, SEACOO will always supply the best quality products for customer.'
	},
	fr: {
		name: 'fr',
		class: 'card2',
		imgUrl: '/images/business/future-resource.png',
		style: {
			width: '50%'
		},
		info:
			'future Resource Itd is a company founded in nova scotia and mainly focus on Canadian diving fish seafood products, start from 2018, company been invested 10 million dollar on geoduck quota, sea cucumber quota and sea urchin quota, after two years development, company has achieved very steady progress on fishing and processing and marketing, in 2020, company opened process and more retail packing products development.'
	},
	freshook: {
		name: 'freshook',
		class: 'card3',
		imgUrl: '/images/business/freshook.png',
		style: {
			width: '70%'
		},
		info:
			'freshook Inc. was founded in 2017 and established a processing plant in Qingdao, China in the same year. The company is committed to the development of breaded aquatic products, including breaded cod, breaded squid, breaded scallops, breaded shrimp, etc.'
	}
}

export { languageEn, languageZh }
