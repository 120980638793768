export const languageZh = {
	translations: {
		home: '首页',
		about: '简介',
		'about us': '关于我们',
		'about Us': '公司简介',
		business: '业务',
		busiNess: '旗下业务',
		responsibility: '企业责任',
		'our responsibility': '企业责任',
		contact: '联系',
		'MAKE INNOVATION A ROUTINE, ': '把创新做成常规，',
		'TAKE THE ROUTINE TO THE EXTREME.': '把常规做到极致。',
		'our business': '旗下业务',
		halifax: '哈利法克斯',
		vancouver: '温哥华',
		qingdao: '青岛',

		//home page
		"RIGHT SOURCE GROUP LIMITED, located in Halifax, Nova Scotia, was founded in 2011. RSGL have high reputation in the market. At present, the company's independent brand SEACOO has become reputational and leads the market with a sales growth rate of 30% per year.":
			'RIGHT SOURCE GROUP LIMITED成立于2011年，位于新斯科舍省哈利法克斯。RSGL在市场上享有很高的声誉。目前，公司的独立品牌SEACOO赢得了声誉，并以每年30％的销售增长率领先于市场。',
		'We take responsibility for consumers, communities and the environment. We do not take profit as the only goal. We emphasize the concern for human value in the production process, and emphasize the contribution to the environment, consumers and society.':
			'我们对消费者，社区和环境负责。 我们不以赢利为唯一目标。 我们强调在生产过程中对人类价值的关注，并强调对环境，消费者和社会的贡献。',

		//business page
		'SEACOO was built in 2011, over 10 years of developing in Canadian sea cucumber industry, it becomes the biggest sea cucumber supplier in North America, two processing plants in each of Halifax and Vancouver been processing total of 6 million lbs of Canadian sea cucumber each year, with a professional quality control team under CFIA approval, SEACOO will always supply the best quality products for customer.':
			'SEACOO成立于2011年，在加拿大海参行业发展超过10年，已成为北美最大的海参供应商，分别在哈利法克斯和温哥华有两家加工厂，每年加工总计600万磅的加拿大海参。SEACOO拥有经过CFIA认可的专业质量控制团队，将始终为客户提供最优质的产品。',
		'future Resource Itd is a company founded in nova scotia and mainly focus on Canadian diving fish seafood products, start from 2018, company been invested 10 million dollar on geoduck quota, sea cucumber quota and sea urchin quota, after two years development, company has achieved very steady progress on fishing and processing and marketing, in 2020, company opened process and more retail packing products development.':
			'Future Resource Itd是一家在新斯科舍省成立的公司，主要专注于加拿大潜水鱼海鲜产品，从2018年开始，已投资1000万加元用于象拔蚌配额，海瓜配额和海胆配额，经过两年的发展，公司已实现在捕捞，加工和销售方面取得了非常稳定的进展，2020年，公司在Vancouver Island开设了一家新工厂，主要专注于增值工艺和更多零售包装产品的开发。',
		'freshook Inc. was founded in 2017 and established a processing plant in Qingdao, China in the same year. The company is committed to the development of breaded aquatic products, including breaded cod, breaded squid, breaded scallops, breaded shrimp, etc.':
			'Freshook Inc.成立于2017年，同年在中国青岛建立了加工厂。该公司致力于开发面包屑水产品，包括面包鳕鱼，面包鱿鱼，面包扇贝，面包虾等。',

		//responsibility page
		'Community responsibility': '企业责任',

		Sustainability: '可持续发展',
		'Fisheries and aquaculture play a key role in the nutrition of hundreds of millions of people around the world every day. But with climate change, fishing pressure and pollution from various human activities are causing ocean acidification and declining biodiversity. Instead of fishing unrestrainedly or causing a heavy load to the ocean, we insist on developing our fishing industry in a sustainable way. ':
			'渔业和水产养殖业每天在全球成千上万人的营养饮食中起着关键作用。但是随着气候变化，捕鱼压力和各种人类活动造成的污染正在导致海洋酸化和生物多样性下降。我们坚持以可持续的方式发展我们的捕鱼业，而不是肆无忌怛地抓捕或给海洋造成负担。',
		'We will also carefully screen and return the undeveloped marine life to the ocean during the process of fishing, to ensure the sustainable development of the marine environment. We believe that only the healthy oceans and sustainable ecosystem for marine life can provide critical support functions upon which human health and well-being depend.':
			'同时，我们还将在捕鱼过程中仔细筛选未发育的海洋生物并将其送回海洋，以确保海洋环境的可持续发展。我们认为，只有健康的海洋环境，和可持续的海洋生态系统才是提供人类健康和福祉所依赖的关键。',

		'Our Commitment to People': '员工保障',
		'Ensuring the occupational safety, health, and welfare of workers is one of the main achievements we have since we have built our business. We provide professional training and health insurance for every position, to give our employees a safe environment and guarantee.':
			'自建立业务以来，确保工人的职业安全，健康和福利是我们的主要成就之一。我们为每个职位提供专业培训和健康保险，为我们的员工提供安全的环境和保证。',
		'At the same time, the self-development of employees is the culture of our company. We encourage our employees to learn more skills and knowledge in different disciplines. We truly believe that we are growing with the development of our employees, not only with their increasing skills and abilities, but also with their border views and innovation.':
			'同时，员工的自我发展是我们公司的文化。我们鼓励员工学习更多不同学科的技能和知识。我们坚信，随着员工技能和能力的提高，以及他们的观念的拓展和思维创新，我们公司能伴随着员工的发展而成长。',

		'Environmental-Friendly': '环境友好',
		'The oceans are under increasing stress from multiple urban and rural sources of pollution. Those are causing damage to the marine environment and lives, and for example, pharmaceutical residues, microplastics, or other substances can have harmful consequences for human health.':
			'海洋受到来自城市和农村多种污染源的越来越大的压力。这些污染源正在破坏海洋环境和生命。如药物残留，微塑料或其他物质可能对人体健康造成有害影响。',
		'As an environmental-friendly and user-central company, protecting the ocean and preventing marine pollution is one of the main duties and responsibilities, not only we intend to ensure the high quality of our products, but also contribute our power to the sustainable development of the marine industry of Canada. Meanwhile, we employ environmentally friendly processes in our production, to ensure sustainable consumption and production patterns.':
			'作为一家支持环保和以用户为中心的公司，保护海洋和防止海洋污染是我们公司主要职责之一。保护海洋生态不仅能确保我们产品的安全性和质量，同时也是我们为加拿大海洋工业的可持续发展作出贡献。另外，我们在生产中采用环保工艺，以确保可持续的消费和生产方式。',

		// about page
		founded: '创立',
		'RIGHT SOURCE GROUP LIMITED, located in Halifax, Nova Scotia, was founded in 2011. The company’s products, such as dried sea cucumber and fish maw, have high reputation in the market.':
			'加拿大 RIGHT SOURCE 有限公司成立于2011年，公司位于新斯科舍省哈利法克斯市，经过多年发展，公司的加拿大东西海岸海参以及鱼胶等海洋类产品受到了市场的一致好评。',

		Development: '发展',
		'Since 2017, RIGHT SOURCE GROUP LIMITED has invested more than 5 million Canadian dollars to acquire sea cucumber resources on the east and west coasts of Canada, and actively develop marine health products. We hope to drive a larger labor market in the near future to meet greater market demand. Today, the company has dozens of bestsellers. These products are sold all over the world and their distribution points are all over North America and China.':
			'自创立以来，公司旗下的加拿大海参品牌SEACOO家喻户晓，并以每年30%的销量增长率引领供应市场。公司长期以来与当地渔民和工厂的深入合作与沟通，建立了稳固的供应链模式，帮助了当地新兴的渔业产业快速的融入到中国以及世界舞台。',

		'The Present': '现在',
		"At present, the company's independent brand SEACOO has become extremely famous and leads the market with a sales growth rate of 30% per year. The company has developed in-depth cooperation and communication with local fishermen and factories, and has established a solid supply chain, which played a key role in the process of integrating local stealth fishery vibrato into the world stage.":
			'公司更是从2017年开始先后投入1000多万加币在加拿大东西海岸收购海参资源，积极开发海洋类养生系列产品，希望在不久的将来可以带动更大的劳动力市场和满足更大的市场需求。如今的RSG旗下拥有畅销精典产品数十种，远销世界各地，其经销点遍布北美和中国。',

		Resource: '资源',
		"Canadian dried sea cucumber is the company's main product, divided into two products, which are the East Coastsea cucumber and the West Coast Red Sea Cucumber. The company has approximately 6 million pounds of the East Coast sea cucumber quotas, and a total of four large fishing vessels to harvest throughout the year. The company directly or indirectly controls approximately 600,000 pounds of red sea cucumber quotas on the West Coast, with two fishing vessels and four divers. At the same time, the company has established partnerships with a number of local exporters and processing plants and has established supply relationships with retailers such as Lablow and Sobeys.":
			'加拿大野生淡干海参是本司最为主要的产品项目。产品分为东岸北极参和西岸红刺参。东岸北极参产业本司拥有约600万磅海参配额，共有4艘大型捕捞渔船全年捕捞野生北极参。西岸红刺参本司直接或间接控制约100万磅海参配额，共有2艘大型捕捞渔船对阿拉斯加红刺参进行捕捞，一座加工厂进行加工。与此同时，本司与多个当地出口商和渔业工厂建立了密切合作关系，亦与Lablow，Sobeys 等当地零售商建立了互帮共荣的供应关系。',

		MILESTONE: 'RSG发展里程碑',
		'The company was founded in Halifax, Canada': '公司创立于加拿大哈利法克斯',
		'Establish close cooperative relations with Canadian local exporters and fishery factories':
			'和加拿大当地出口商和渔业工厂建立密切合作关系',
		'Established supply relationships with local retailers such as Lablow and Sobeys':
			'与Lablow，Sobeys 等当地零售商建立了供应关系',
		'Launched marine health food development projects, and cooperated with scientific research institutions and local universities to develop sea cucumber health products':
			'启动海洋健康食品开发项目，与科研机构和本地大学合作开发海参保健养生产品',
		'Beche Sea Cucumber Capsule Health Products was launched and received attention and praise from various industries':
			'Beche海参胶囊保健品上市，收到行业各家的关注与好评',
		'Established a West Coast processing base at Albeney Port on Vancouver Island and coordinated development with the East Coast':
			'在温哥华岛的Albeney港口建立，西海岸加工基地与东海岸相互协同发展',

		'Fully develop seafood import and export business':
			'全面开展海鲜进出口业务',
		'The Canadian sea cucumber brand seacoo was officially launched, the development of sea cucumbers on the east and west coasts of Canada was started, and a good relationship was established with the local sea cucumber industry':
			'正式推出加拿大海参品牌seacoo，启动加拿大东西海岸海参的开发，并和当地海参产业建立了良好关系',
		'Started the construction of the freshook factory in Qingdao, laying the foundation for the expansion of the Chinese market':
			'在青岛开始freshook工厂建设为中国市场扩展打下基础',
		"The construction of Freshook's deep processing plant for aquatic products has been completed, and the breaded cod products and deep-processed sea cucumber products have begun to be sold to the Chinese market":
			'Freshook水产品深加工工厂建设完成，裹粉类鳕鱼产品以及海参深加工产品，开始面向中国市场',
		'Established the future resource company to purchase and integrate fishery resources, and successively purchased fishery resources on the west coast of Canada for more than 10 million Canadian dollars':
			'成立future resource公司进行对渔业资源的收购和整合，先后在加拿大西海岸收购渔业资源一千多万加币',

		'Our Locations': '办公地点',
		VANCOUVER: '温哥华',
		SEATTLE: '西雅图',
		HALIFAX: '哈利法克斯 (HQ)',
		'NEW YORK': '纽约',
		QINGDAO: '青岛',
		HONGKONG: '香港',

		// contact page
		'Contact Form': '发送信息',
		'Contact<br />Information': '联系方式',
		'Your message has been sent!': '您的信息已发送！',
		Name: '姓名',
		'E-mail': '电子邮箱',
		Message: '消息',
		'Please input a valid e-mail address': '请输入正确的电子邮箱地址',
		Phone: '电话',
		'Toll Free': '免费电话'
	}
}
