import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Logo from './Logo'

import {
	AppBar,
	Toolbar,
	Tabs,
	Tab,
	Typography,
	createMuiTheme,
	ThemeProvider,
	Slide,
	useScrollTrigger
} from '@material-ui/core'

import { useTranslation } from 'react-i18next'

import './style.scss'

import { pathObj } from '../../constants/index'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#fff 0'
		}
	}
})

function Header() {
	const { t, i18n } = useTranslation()
	let history = useHistory()
	const [value, setValue] = useState('')
	const trigger = useScrollTrigger({ target: window })
	return (
		<Slide appear={false} direction='down' in={!trigger}>
			<AppBar position='sticky' color='default' className='top-nav-header'>
				<Toolbar>
					<Logo />
					<ThemeProvider theme={theme}>
						<Tabs
							className='top-nav'
							value={history.location.pathname !== '/' ? value : false}
							indicatorColor='primary'
						>
							<Tab
								value=''
								className={
									history.location.pathname === '/' ? 'selected-nav' : 'nav'
								}
								label={t('home')}
								onClick={() => {
									history.push(`/${pathObj.home.pathName}`)
									setValue('home')
									window.scrollTo(0, 0)
								}}
								style={
									history.location.pathname === '/'
										? { color: '#2d5d72' }
										: { color: '#4d4d4e' }
								}
							/>
							<Tab
								value='about'
								className={
									history.location.pathname === '/about'
										? 'selected-nav'
										: 'nav'
								}
								label={t('about')}
								onClick={() => {
									history.push(`/${pathObj.about.pathName}`)
									setValue('about')
									window.scrollTo(0, 0)
								}}
								style={
									history.location.pathname === '/about'
										? { color: '#2d5d72' }
										: { color: '#4d4d4e' }
								}
							/>
							<Tab
								value='business'
								className={
									history.location.pathname === '/business'
										? 'selected-nav'
										: 'nav'
								}
								label={t('business')}
								onClick={() => {
									history.push(`/${pathObj.business.pathName}`)
									setValue('business')
									window.scrollTo(0, 0)
								}}
								style={
									history.location.pathname === '/business'
										? { color: '#2d5d72' }
										: { color: '#4d4d4e' }
								}
							/>
							<Tab
								value='responsibility'
								className={
									history.location.pathname === '/responsibility'
										? 'selected-nav'
										: 'nav'
								}
								label={t('responsibility')}
								onClick={() => {
									history.push(`/${pathObj.responsibility.pathName}`)
									setValue('responsibility')
									window.scrollTo(0, 0)
								}}
								style={
									history.location.pathname === '/responsibility'
										? { color: '#2d5d72' }
										: { color: '#4d4d4e' }
								}
							/>
							<Tab
								value='contact'
								className={
									history.location.pathname === '/contact'
										? 'selected-nav'
										: 'nav'
								}
								label={t('contact')}
								onClick={() => {
									history.push(`/${pathObj.contact.pathName}`)
									setValue('contact')
									window.scrollTo(0, 0)
								}}
								style={
									history.location.pathname === '/contact'
										? { color: '#2d5d72' }
										: { color: '#4d4d4e' }
								}
							/>
						</Tabs>
					</ThemeProvider>
					<div className='nav-trans'>
						<Typography
							className='trans trans-en'
							style={i18n.language === 'en' ? { fontWeight: 'bold' } : {}}
							onClick={() => {
								if (i18n.language === 'zh') {
									i18n.changeLanguage('en')
								}
							}}
						>
							EN
						</Typography>
						<Typography
							className='trans trans-zh'
							style={i18n.language === 'zh' ? { fontWeight: 'bold' } : {}}
							onClick={() => {
								if (i18n.language === 'en') {
									i18n.changeLanguage('zh')
								}
							}}
						>
							中
						</Typography>
					</div>
				</Toolbar>
			</AppBar>
		</Slide>
	)
}

export default Header
