import React from 'react'

import Header from './component/Header/Header'
import Footer from './component/Footer/Footer'
import Home from './pages/Home/Home'
import About from './pages/About/About'
import Business from './pages/Business/Business'
import Contact from './pages/Contact/Contact'
import Responsibility from './pages/Responsibility/Responsibility'

import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'

const App = () => {
	return (
		<div className='App'>
			<Router>
				<Header />
				<Switch>
					<Route exact path='/' component={Home} />
					<Route path='/about' component={About} />
					<Route path='/business' component={Business} />
					<Route path='/contact' component={Contact} />
					<Route path='/responsibility' component={Responsibility} />
				</Switch>
				<Footer />
			</Router>
		</div>
	)
}

export default App
