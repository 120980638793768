import React from 'react'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { Typography, Grid } from '@material-ui/core'

import { pathObj } from '../../constants/index'

import './style.scss'

const Home = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const bannerWidth = document.body.offsetWidth
	const bannerHeight = bannerWidth / 2.67
	const bodyHeight = bannerWidth / 1.5

	return (
		<div id='home'>
			<div className='home-banner' style={{ minHeight: bannerHeight }}>
				<img src='/images/home/homebanner.jpg' alt='homeBanner' />
				<Typography className='title' variant='h1'>
					{t('MAKE INNOVATION A ROUTINE, ')}
					<br />
					{t('TAKE THE ROUTINE TO THE EXTREME.')}
				</Typography>
			</div>
			<div className='home-about' style={{ height: bodyHeight }}>
				<Grid container>
					<Grid item xs={6} className='about-left'>
						<div className='text-container'>
							<Typography
								className='title'
								onClick={() => {
									history.push(`/${pathObj.about.pathName}`)
									window.scrollTo(0, 0)
								}}
							>
								{t('about us')}
							</Typography>
							<Typography className='info'>
								{t(
									"RIGHT SOURCE GROUP LIMITED, located in Halifax, Nova Scotia, was founded in 2011. RSGL have high reputation in the market. At present, the company's independent brand SEACOO has become reputational and leads the market with a sales growth rate of 30% per year."
								)}
							</Typography>
						</div>
					</Grid>
					<Grid item xs={6} className='about-right'>
						<img
							src='/images/home/home-about.png'
							alt='holder'
							className='imgs about-img'
						/>
					</Grid>
				</Grid>
			</div>
			<div className='home-business' style={{ height: bodyHeight }}>
				<Typography
					className='title'
					onClick={() => {
						history.push(`/${pathObj.business.pathName}`)
						window.scrollTo(0, 0)
					}}
				>
					{t('busiNess')}
				</Typography>
				<div className='img-group'>
					<img
						src='/images/home/seacoo-logo.png'
						alt='holder'
						className='imgs img1'
					/>
					<img src='/images/home/fr-logo.png' alt='holder' className='imgs' />
					<img
						src='/images/home/freshook-logo.png'
						alt='holder'
						className='imgs'
					/>
				</div>
			</div>
			<div className='home-responsibility' style={{ height: bodyHeight }}>
				<Grid container>
					<Grid item xs={7} className='about-left'>
						<div className='text-container'>
							<Typography
								className='title'
								onClick={() => {
									history.push(`/${pathObj.responsibility.pathName}`)
									window.scrollTo(0, 0)
								}}
							>
								{t('responsibility')}
							</Typography>
							<Typography className='info'>
								{t(
									'We take responsibility for consumers, communities and the environment. We do not take profit as the only goal. We emphasize the concern for human value in the production process, and emphasize the contribution to the environment, consumers and society.'
								)}
							</Typography>
						</div>
					</Grid>
					<Grid item xs className='about-right'>
						<img
							src='/images/home/home-responsibility.png'
							alt='holder'
							className='imgs about-img'
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

export default Home
