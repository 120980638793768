import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
	Typography,
	Grid,
	TextField,
	Button,
	Snackbar
} from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { LocationCity, Phone } from '@material-ui/icons'

import { Message } from '../../models'

import { sendMessage } from '../../api'

import isEmail from 'validator/lib/isEmail'

import './style.scss'

const Contact = () => {
	const { t } = useTranslation()
	const bannerWidth = document.body.offsetWidth
	const bannerHeight = bannerWidth / 2.57
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [content, setContent] = useState('')
	const [sendError, setSendError] = useState(false)
	const [showSnackbar, setShowSnackbar] = useState(false)

	const handleSendMessage = () => {
		if (!name || !email || !content) {
			setSendError(true)
			return
		}
		if (!isEmail(email)) {
			return
		}
		const message: Message = {
			name: name,
			email: email,
			content: content
		}
		sendMessage(message)
		setShowSnackbar(true)
		setName('')
		setEmail('')
		setContent('')
		setSendError(false)
	}

	const snackClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return
		}
		setShowSnackbar(false)
	}

	const Alert = (props: AlertProps) => {
		return <MuiAlert elevation={6} variant='filled' {...props} />
	}
	return (
		<div id='contact'>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={showSnackbar}
				autoHideDuration={3000}
				onClose={snackClose}
			>
				<Alert onClose={snackClose} severity='success'>
					{t('Your message has been sent!')}
				</Alert>
			</Snackbar>
			<div className='contact-banner' style={{ minHeight: bannerHeight }}>
				<img src='/images/contact/contactbanner.jpg' alt='contactBanner' />
				<Typography
					className='title'
					dangerouslySetInnerHTML={{
						__html: t('Contact<br />Information')
					}}
				></Typography>
			</div>
			<div className='contact-body-container'>
				<Grid container className='contact-body'>
					<Grid item xs={6} className='body-left'>
						<img src='/images/contact/back-card.png' alt='background' />

						<div className='form-container'>
							<Typography variant='h4' className='form-header'>
								{t('Contact Form')}
							</Typography>
							<Typography className='text-field-title'>
								{t('Name') + ' *'}
							</Typography>
							<TextField
								fullWidth
								value={name}
								variant='outlined'
								required
								error={!name && sendError}
								size='small'
								onChange={e => setName(e.target.value)}
							/>
							<Typography className='text-field-title'>
								{t('E-mail' + ' *')}
							</Typography>
							<TextField
								fullWidth
								value={email}
								variant='outlined'
								required
								type='email'
								error={(!email && sendError) || (!!email && !isEmail(email))}
								helperText={
									sendError && t('Please input a valid e-mail address')
								}
								size='small'
								onChange={e => setEmail(e.target.value)}
							/>
							<Typography className='text-field-title'>
								{t('Message') + ' *'}
							</Typography>
							<TextField
								multiline
								value={content}
								fullWidth
								rows={8}
								error={!content && sendError}
								variant='outlined'
								required
								onChange={e => setContent(e.target.value)}
							/>
							<Button onClick={handleSendMessage}>Send</Button>
						</div>
					</Grid>
					<Grid item xs={6} className='body-right'>
						<img src='/images/contact/back-card.png' alt='background' />
						<div className='contact-method'>
							<div className='address'>
								<Grid container spacing={2}>
									<Grid item>
										<LocationCity style={{ fontSize: 19, color: '#2d5d72' }} />
									</Grid>
									<Grid item>
										<Typography>
											421 Bluewater Rd,
											<br />
											Bedford, NS B4B 1J7
										</Typography>
									</Grid>
								</Grid>
							</div>
							<div className='phone'>
								<Grid container spacing={2}>
									<Grid item>
										<Phone style={{ fontSize: 19, color: '#2d5d72' }} />
									</Grid>
									<Grid item>
										<Typography>
											{t('Phone')}: 902-706-1820
											<br />
											{t('Toll Free')}: +1 (866)-200-2661
										</Typography>
									</Grid>
								</Grid>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

export default Contact
