import axios from 'axios'
import { Message } from '../models/index'

axios.defaults.baseURL = process.env.REACT_APP_URL

const sendMessage = (message: Message) => {
	axios.post('/general/rsgl/message', { message })
}

export { sendMessage }
