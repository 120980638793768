import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Breadcrumbs, Link } from '@material-ui/core'

import { pathObj } from '../../constants/index'

import './style.scss'

const Footer = (props: any) => {
	const { t } = useTranslation()
	let history = useHistory()
	return (
		<Breadcrumbs className='footer-nav'>
			<Link
				onClick={() => {
					history.push(`/${pathObj.home.pathName}`)
					window.scrollTo(0, 0)
				}}
			>
				{t('home')}
			</Link>
			<Link
				onClick={() => {
					history.push(`/${pathObj.about.pathName}`)
					window.scrollTo(0, 0)
				}}
			>
				{t('about us')}
			</Link>
			<Link
				onClick={() => {
					history.push(`/${pathObj.business.pathName}`)
					window.scrollTo(0, 0)
				}}
			>
				{t('business')}
			</Link>
			<Link
				onClick={() => {
					history.push(`/${pathObj.home.pathName}`)
					window.scrollTo(0, 0)
				}}
			>
				{t('responsibility')}
			</Link>
			<Link
				onClick={() => {
					history.push(`/${pathObj.contact.pathName}`)
					window.scrollTo(0, 0)
				}}
			>
				{t('contact')}
			</Link>
		</Breadcrumbs>
	)
}

export default Footer
